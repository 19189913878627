import React, { useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import './ContactForm.scss';

const ContactForm = ({handleUser}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [sent, setSent] = useState(false);

  const [err, setErr] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const handleSubmit = (e) => {
    if (e.target.checkValidity()) {
      e.preventDefault();
      axios({
        method: 'POST',
        data: {
          name,
          to: email,
          text: message,
        },
        url: `${process.env.API_ENDPOINT}/email/submitContact`,
        withCredentials: true,
      }).then(() => {
        setSent(true);
      }).catch(() => {
        setErr(true);
        setErrMsg('Error: Could not send message.');
      });
    }
  };

  return (
    <div className="contact-form">
      {err ? <Alert variant="danger">{errMsg}</Alert> : null}
      {sent ? (
        <div className="contact-form-sent">
          <i
            className="bi bi-check-circle-fill"
            style={{ fontSize: '3rem', color: '#00b300' }}
          />
          <h2>Thank you for your response!</h2>
        </div>
      ) : (
        <form onSubmit={(e) => handleSubmit(e)}>
          <label className="required" htmlFor="name">
            Name:
          </label>
          <input
            type="text"
            id="name"
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label className="required" htmlFor="email">
            Email:
          </label>
          <input
            type="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label className="required" htmlFor="message">
            Message:
          </label>
          <textarea
            id="message"
            name="message"
            rows="5"
            maxLength="5000"
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <p>{message.length}/5000 characters</p>
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </form>
      )}
    </div>
  );
};

export default ContactForm;
