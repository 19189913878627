import BodyBox from '../layouts/bodyBox';
import ContactForm from '../components/ContactForm';
import React from 'react';
import SEO from '../components/Seo';

const Contact = () => {
  return (
    <BodyBox>
      <SEO title="Contact" />
      <h1>Get In Touch With Us</h1>
      <ContactForm />
    </BodyBox>
  );
};

export default Contact;
